export default {
    title: 'Оформлення замовлення',

    head: {
        cart: 'Кошик',
        delivery: 'Доставка',
        payment: 'Оплата',
        thankYou: 'Замовлення оформлено',
        questions: 'Питання',
        workHours: 'з 9:00 до 21:00',
        workDays: 'без вихідних',
    },

    sidebar: {
        statusTitle: 'Статус замовлення',
    },

    delivery: {
        title: 'Обери адресу доставки',
        titleType: 'Обери спосіб доставки',
        toPayment: 'Продовжити оформлення',
        city: 'Населений пункт',
        priceInfo: 'Вартість та термін доставки залежать від способу доставки. На наступному кроці ти зможеш уточнити цю інформацію.',
        addressTitle: 'Адреса доставки',
        addressInfo: 'Ми використовуємо контакті дані тільки для того, щоб інформувати тебе про замовлення.',
        warehouseTitle: 'Адреса відділення',
        warehouseInfo: 'Будь ласка, уточни адресу, звідки тобі зручно забрати замовлення, або обери найближче відділення',
        onMap: 'на мапі',
        wontSubscribe: 'Я хочу отримувати інформацію про новинки, акції та новини adidas.',
        confidentiality: 'Я приймаю <a href="/gdpr" target="_blank">Політику Конфіденційності</a> та <a href="/rules" target="_blank">Умови продажу</a>',
        loginNotification: 'Увійди в акаунт для швидкого оформлення замовлення',
        rulesTitle: 'Умови доставки',
        typeFreeDelivery1: 'Безкоштовна доставка при замовленні від 2500 грн.',
        typeFreeDelivery1Temp: 'Безкоштовна доставка для замовлень зроблених з 05.12 до 06.12.',
        typeFreeDelivery2: 'Безкоштовна доставка без обмежень по сумі замовлення',
        turnThis: 'Забрати тут',
        readMore1: 'Подробиці',
        readMore2: 'Згорнути',
        adidasWarehouseInfo: 'Ми розширюємо мережу поштоматів з безкоштовною доставкою. Скоро тут з\'являться нові магазини.',
    },

    payment: {
        title: 'Оплата та підтвердження замовлення',
        choosePaymentType: 'Вибери зручний спосіб оплати',
        cardType: 'Сплатити карткою на сайті онлайн',
        postpaidType: 'Сплатити при отриманні',
        postpaidInfo: 'При отриманні замовлення у відділенні Нова Пошта або Justin можна сплатити карткою або готівкою.',
        approveOrder: 'Підтвердити замовлення',

        or: 'або',
        buy: 'Сплатити',
        cartNumber: 'Номер картки',
        expireLabel: 'ММ/РР',
        expireInfo: 'Термін дії',
        cvvInfo: 'Код CVV2/CVC2',
        cvvPopover: '3-значний код назвороті картки',

        sale: {
            paymentByCard: '(додаткова знижка 5%)'
        },

        monobank: {
            title: 'monobank. Покупка частинами',
            monthCount: 'Кількість платежів: ',
            monthPay: 'Щомісячний платіж:',
            month: 'місяць',
            btn: 'Оформити',
            infoText: '<b>Зверни увагу:</b> для покупки, тобі має бути доступний ліміт на "Покупку частинами" в додатку monobank. У разі виникнення питань щодо роботи додатку, звертайся на гарячу лінію monobank (ПАТ «Універсалбанк») 0800205205',
            popover: 'Введи свій номер телефону, до якого  прив\'язаний аккаунт monobank',
        },
    },

    confirmation: {
        title: 'Твоє замовлення успішно оформлено',
        orderNumber: 'Номер замовлення',
        welcomeText: 'Привіт, {name}, дякуємо, що купуєш в adidas! Ми відправимо підтвердження на email <b>{email}</b> у найближчий час.',
        deliveryDateTitle: 'Твоє замовлення буде доставлено:',
        infoOrder: 'Ти отримаєш лист на email та СМС, коли твоє замовлення буде відправлено зі складу.',
        askTitle: 'Щось не так?',
        askText: 'Залишились питання? Шукай відповіді <a href="/help">у розділі Допомога</a>',
        deliveryDate: 'Доставка у період:',
    }
}
